<template>
<!--  <simple-display></simple-display>-->
  <display-items></display-items>
</template>

<script>

// import SimpleDisplay from "@/views/web/display/content/index"
import DisplayItems from "@/views/web/display/content/display_items.vue";

export default {
  name: "index",
  components:{
    // SimpleDisplay:SimpleDisplay
    DisplayItems:DisplayItems
  },
  created() {
  },
  methods:{

  }
};
</script>

<style scoped>

</style>
