<template>
  <div class="display_items">
    <div class="display-header">
        <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Display Resource") }}
    </div>
    <div class="content">
      <div class="banner">
        <el-image class="img" :src="banner['image_url']" :alt="banner.title"></el-image>
      </div>
      <div class="series">
        <div class="series-title" style="font-size: 22px;font-weight: 500" > Series Display </div>
        <div class="series-list" :style="'--seriessize:' + seriessize">
            <el-image v-for="item in seriesList" class="img"
                      :src="item['image_url']"
                      :key="item['id']"
                      @click="handleJumpLink(item['id'])" style="cursor: pointer;">
            </el-image>
        </div>
      </div>
      <div class="list">
        <div class="list-title" style="font-size: 22px;font-weight: 500">Category Display</div>
        <div class="list-imgs" :style="'--tempsize:' + tempsize">
          <el-image class="list-img" v-for="item in list"
                    :src="item['image_url']"
                    :key="item['id']"
                    @click="handleJumpLink(item['id'])" style="cursor: pointer;">
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "display_items",
  data(){
    return {
      displayItems:[],
      banner:{},
      seriesList:[],
      list:[],
      seriessize:0,
      tempsize:0
    }
  },
  created() {
    this.handleGetAllDisplayItems()
  },
  methods:{
    handleGetAllDisplayItems(){
      this.$axios.get('/w1/displayitems').then((response) => {
        const { data } = response.data;
        this.displayItems = data
        this.banner = this.displayItems.banner['items'][0];
        this.seriesList = this.displayItems.series['items'];
        this.list = this.displayItems.list['items'];
        this.seriessize = this.seriesList.length%3
      })
    },
    handleJumpLink(id){
      this.$router.push({path:'/web/display/detail',query:{id:id}});
    }
  }
};
</script>

<style scoped lang="scss">
.display_items {

  padding: 20px;

  .display-header{
    font-size: 18px; width: 1400px; margin: 0 auto; color: #515a6e
  }

  .content{

    padding: 20px;

    .banner{
      text-align: center;
      .img{
        width: 1400px;
        height:315px ;
      }
    }

    .series{
      .series-title{
        text-align: center;
        font-size: 18px;
        padding: 45px 0 20px 0;
      }
      .series-list{
        display: flex;
        flex-wrap: wrap;
        width: 1400px;
        justify-content: space-between;
        margin: 0 auto;
        &:after{
          content: "";
          width:calc(1400px - var(--seriessize)*455px - var(--seriessize) * 2 * 9px);
          display: block;
          height: 0;
        }
        .img{
          width: 455px;
          height: 330px;
          padding: 9px 0;
        }
      }
    }


    .list{
      .list-title{
        text-align: center;
        font-size: 18px;
        padding: 50px 0 20px 0;
      }
      .list-imgs{
        display: flex;
        flex-wrap: wrap;
        width: 1400px;
        justify-content: space-between;
        margin: 0 auto;
        &:after{
          content: "";
          width:calc(1400px - var(--tempsize)*272px - var(--tempsize) * 2 * 5px - 10px);
          display: block;
          height: 0;
        }
        .list-img{
          width: 272px;
          height: 113px;
          padding: 5px 0;
        }
      }
    }

  }
}
</style>
